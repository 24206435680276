require('./bootstrap');

var Dragdealer = require('dragdealer');

$(function() {
    
    // Iniciando o popper.js
    $('[data-toggle="tooltip"]').tooltip();

    // Iniciando o Dragdealer
    new Dragdealer('seguradorasParceirasDrag', {
        loose: true
    });

    // Barra inferior (ligue-me)
    $('.ligue-btn').click(function(event) {
        event.preventDefault();
        $(this).parent().find('.ligue-modal').show();
    });
    $('.ligue-btn-close').click(function(event) {
        event.preventDefault();
        $(this).parents('.ligue-modal').hide();
    });

    // Escolha das cidades, na barra ligue-me
    $('#ligueCidadeEscolha').change(function(event) {

        $(this).parents('.ligue-modal').hide();

        var telefone = $('#ligueCidadeEscolha option:selected').val();
        var cidade = $('#ligueCidadeEscolha option:selected').text();

        if (telefone != '') {
            $('.fale-conosco-telefone-container').show();
        } else {
            $('.fale-conosco-telefone-container').hide();
        }

        $('.ligue-telefone-text-switcher').text(telefone);
        $('.ligue-cidade-text-switcher').text(cidade);
    });

    // Escolha das cidades, na sessão "localização"
    $('#enderecoCidadeEscolha').change(function(event) {
        var source = $('#enderecoCidadeEscolha option:selected').val();
        $('#googleMaps iframe').remove();
        $('#googleMaps').append('<iframe src="' + source + '" tabindex="0"></iframe>')
    });
});

var servicesNavBar = document.getElementById('servicesNavBar');

if (servicesNavBar.classList.contains('toggleable')) {
    servicesNavBar.addEventListener('mouseover', function(event) {
        event.preventDefault();
        servicesNavBar.classList.add('is-open');
    });
    servicesNavBar.addEventListener('mouseout', function() {
        servicesNavBar.classList.remove('is-open');
    });
}

/**
 * Máscara para telefones e
 * celulares (variável dependendo 
 * do número de caractéres).
 * 
 */
function inputHandler(masks, max, event) {
    var c = event.target;
    var v = c.value.replace(/\D/g, '');
    var m = c.value.length > max ? 1 : 0;
    VMasker(c).unMask();
    VMasker(c).maskPattern(masks[m]);
    c.value = VMasker.toPattern(v, masks[m]);
}
// var phoneMask = ['(99) 9999-9999', '(99) 9 9999-9999'];
// var phone = document.querySelector('#telefone');
// VMasker(phone).maskPattern(phoneMask[0]);
// phone.addEventListener('input', inputHandler.bind(undefined, phoneMask, 14), false);